import React from "react";
import {graphql, PageProps, Link} from "gatsby";
import {Query } from '../../graphql-types';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const Blog: React.FC<PageProps<Query>> = (props) => {
  const remark = props.data?.allMarkdownRemark?.edges;

  const links = remark.map(edge => {
    return (
      <li key={edge.node.frontmatter.slug}>
        <Link to={`/blog/${edge.node.frontmatter.slug}`} className="no-underline">
          <span className="underline">
          {edge.node.frontmatter.title}
          </span>
          <span className="text-gray-300 text-sm">
            <> - </>
            <span>{edge.node.frontmatter.date}</span>
          </span>
        </Link>
      </li>
    )
  })

    return (
      <Layout>
        <Helmet><title>Blog</title></Helmet>
        <h1 className="mb-4">Blog</h1>
        <ul>
          {links}
        </ul>
      </Layout>
    );
};

export default Blog;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`
